import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getNumberInFormat, getTimeInFormat } from '../utils';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="xAxisLabel">{`${payload[0].name==='variableValue' ? 
          'time: '+getTimeInFormat(payload[0].payload?.time) : payload[0].payload?.rangeName}`}</p>
        <p className="yAxisLabel">{`${payload[0].name} : ${getNumberInFormat(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
};

export default withTranslation()(CustomTooltip);

CustomTooltip.propTypes = {
  t: PropTypes.func, //HOC
  active: PropTypes.bool,
  payload: PropTypes.array,
};
